<!--
 * @Description: 驱动管理 - 任务新增
 * @Author: kecraft
 * @Date: 2024-02-27 10:11:18
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-24 10:18:06
 * @FilePath: /impact-iotos-console/src/views/drive/task/taskAdd.vue
-->
<template>
  <div class="task-add">
    <myDialog @close="handleClose" @ok="handleOk" :title="isEdit ? '编辑任务' : '启动新任务'">
      <div>
        <el-form :rules="isEdit ? rulesEdit : rulesAdd" ref="formTaskAdd" :model="form" label-position="left">
          <el-form-item label="任务名称" prop="name" class="label-feather">
            <el-input v-model="form.name" class="width466" placeholder="请输入任务名称" />
          </el-form-item>
          <el-form-item label="JVM参数" prop="jvmArgs" class="label-feather">
            <el-input v-model="form.jvmArgs" class="width466" placeholder="请输入JVM参数" type="textarea" />
          </el-form-item>
          <el-form-item label="命令参数" prop="args" class="label-feather">
            <el-input v-model="form.args" class="width466" placeholder="请输入命令参数" type="textarea" />
          </el-form-item>
          <el-form-item label="环境变量" prop="envArgs" class="label-feather">
            <el-input v-model="form.envArgs" class="width466" placeholder="请输入环境变量" type="textarea" />
          </el-form-item>
          <el-form-item prop="gatewayDevPk" class="label-feather">
            <template #label>
              <div class="tooltip-left">
                网关设备PK
                <el-tooltip class="box-item" effect="dark"
                  content='<div>网关设备信息：如果要绑定已存在的网关设备，必须填写相关信息，否则会自动创建一个新的网关设备。 </div>' placement="right" raw-content>
                  <el-icon style="padding-left: 20px;">
                    <QuestionFilled />
                  </el-icon>
                </el-tooltip>
              </div>
            </template>
            <el-input v-model="form.gatewayDevPk" class="width466" placeholder="请输入网关设备PK" />
          </el-form-item>
          <el-form-item label="网关设备ID" prop="gatewayDevId" class="label-feather">
            <el-input v-model="form.gatewayDevId" class="width466" placeholder="请输入网关设备ID" />
          </el-form-item>
          <el-form-item label="网关设备密钥" prop="gatewayDevSecret" class="label-feather">
            <el-input v-model="form.gatewayDevSecret" class="width466" placeholder="请输入网关设备密钥" />
          </el-form-item>
          <el-form-item label="连接地址" prop="connectMqttEndpoint" class="label-feather">
            <el-input v-model="form.connectMqttEndpoint" class="width466" placeholder="请输入连接地址" />
          </el-form-item>
          <el-form-item prop="autoStart" class="label-feather">
            <template #label>
              <div class="tooltip-left">
                是否自启动
                <el-tooltip class="box-item" effect="dark" content='<div>自启动：新任务或服务器重启时会自动启动，否则需要手动启动。 </div>'
                  placement="right" raw-content>
                  <el-icon style="padding-left: 20px;">
                    <QuestionFilled />
                  </el-icon>
                </el-tooltip>
              </div>
            </template>
            <el-radio-group v-model="form.autoStart">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
    </myDialog>
  </div>
</template>

<script setup>
import myDialog from "@/components/dialog/myDialog.vue";
import { ref, reactive, defineEmits, defineExpose } from "vue";
import { QuestionFilled } from '@element-plus/icons-vue';
import { ElMessage } from "element-plus";
import { ElLoading } from 'element-plus';
import api from "@/api/api"
const emit = defineEmits(["close"]);
const isEdit = ref(false);
const form = reactive({
  name: "", // 所属产品
  jvmArgs: "",
  args: "",
  envArgs: "",
  remark: "",
  gatewayDevPk: "",
  gatewayDevId: "",
  gatewayDevSecret: "",
  connectMqttEndpoint: "",
  autoStart: true,
})
const taskId = ref(null);
const driverId = ref(null);
const rulesAdd = {
  name: [
    { required: true, message: "任务名称不能为空", trigger: "change" },
  ],
  connectMqttEndpoint: [
    { required: true, message: "连接地址不能为空", trigger: "change" },
  ],
}
const rulesEdit = {
  name: [
    { required: true, message: "任务名称不能为空", trigger: "change" },
  ],
  connectMqttEndpoint: [
    { required: true, message: "连接地址不能为空", trigger: "change" },
  ],
  gatewayDevId: [
    { required: true, message: "网关设备ID不能为空", trigger: "change" },
  ],
  gatewayDevPk: [
    { required: true, message: "网关设备PK不能为空", trigger: "change" },
  ],
  gatewayDevSecret: [
    { required: true, message: "网关设备密钥不能为空", trigger: "change" },
  ],
}
const formTaskAdd = ref(null);
const handleClose = () => {
  emit("close")
}
const handleOk = () => {
  formTaskAdd.value.validate((valid) => {
    if (valid) {
      if (isEdit.value) {
        editSubmit()
      } else {
        const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })
        api.createNewTask(driverId.value, form).then(res => {
          if (res.code === "0") {
            ElMessage.success("创建成功！")
            emit("close", true)
          }
          loading.close();
        })
      }
    } else {
      return false;
    }
  });
}

const editSubmit = () => {
  api.editDriversTask(taskId.value, form).then(res => {
    if (res.code === "0") {
      ElMessage.success("编辑成功！")
      emit("close", true)
    }
  })
}
const init = (row) => {
  const oldForm = JSON.parse(JSON.stringify(row));
  const { name, args, id } = oldForm;
  driverId.value = id;
  form.name = name;
  form.args = args;
}

const initEdit = (row) => {
  isEdit.value = true;
  const oldForm = JSON.parse(JSON.stringify(row));
  const { name, jvmArgs, args, envArgs, remark, gatewayDevPk, gatewayDevId, gatewayDevSecret, connectMqttEndpoint, autoStart, id } = oldForm;
  form.name = name;
  form.jvmArgs = jvmArgs;
  form.args = args;
  form.envArgs = envArgs;
  form.remark = remark;
  form.gatewayDevPk = gatewayDevPk;
  form.gatewayDevId = gatewayDevId;
  form.gatewayDevSecret = gatewayDevSecret;
  form.connectMqttEndpoint = connectMqttEndpoint;
  form.autoStart = autoStart;
  taskId.value = id;
}
defineExpose({ init, initEdit });
</script>

<style lang="less" scoped>
.width700 {
  width: 700px;
}

.width466 {
  width: 466px;
}

.tooltip-left {
  display: flex;
  align-items: center;
}
</style>

<style lang="less">
.label-feather {
  .el-form-item__label {
    width: 160px;
  }
}
</style>